/* Home Section */
.home {
  padding: 0;
  height: 1px;
  min-height: 100vh;
  position: relative;
} 

/* Home Slider */
.home-slider {
  position: relative; 
}
 
/* Home Slider Navigation Buttons */
.home-slider .swiper-button-next,
.home-slider .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--black);
  font-size: 1rem; 
  height: 5rem;
  width: 5rem; 
  padding: 2rem;
}

/* Home Slider Previous Navigation Buttons */
.home-slider .swiper-button-prev{
  left: 0;
}

/* Home Slider Next Navigation Buttons */
.home-slider .swiper-button-next{
  right: 0;
}

/* Home Slider Navigation Buttons, Hover Effect */
.home-slider .swiper-button-next:hover,
.home-slider .swiper-button-prev:hover {
  background-color: var(--main-color);
}

/* Home Slider Navigation Buttons Icon */
.home-slider .swiper-button-next::after,
.home-slider .swiper-button-prev::after {
  font-size: 2rem;
  font-weight: bold;
  color: var(--black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center; 
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Home Slider Navigation Buttons Icon, On Hover */
.home-slider .swiper-button-next:hover::after,
.home-slider .swiper-button-prev:hover::after{
    color: var(--white);
}

/* Home Item */
.home-item {
  min-height: 90vh;
}

/* Home Item Image */
.home-item img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

/* Home Item Content */
.home-item .content {
  padding: 2rem 7%;
  padding-top: 8rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  background:         linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
}

/* Home Item Content Text */
.home-item .content .text {
  width: 80rem;
}

/* Home Item Content Heading */
.home-item .content h3 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 1rem;
  color: var(--white);
}

/* Home Item Content Paragraph */
.home-item .content p{
  font-size: 1.7rem;
  font-weight: 400;
  color: var(--white);
  padding-bottom: 2rem;
  line-height: 1.4;
}