/* FAQ section */
.faq {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    gap: 2rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
}

/* FAQ section Accordion container */
.faq .accordion-container-1,
.faq .accordion-container-2{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}