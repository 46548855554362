/* Counting Section */
.counting {
  padding: 4rem 5%;
  margin: 1rem 0; 
  min-height: 15rem;  
  display: -ms-grid;  
  display: grid;
  -ms-grid-columns: (minmax(22rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 2rem;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
  background:         linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/* Counting Box */
.counting .box {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
}

/* Counting Box Icon */
.counting .box .icon {
  background-color: var(--main-color);
  color: var(--white);
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  outline: 0.2rem solid var(--white);
  outline-offset: -1rem;
  font-size: 4rem;
}

/* Counting Box Count */
.counting .box .count {
  font-size: 4rem;
  font-weight: 500;
  color: var(--white);
}

/* Counting Box Heading */
.counting .box h3 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--white);
}