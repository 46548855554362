/* Comment container */
.comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 2rem; 
  padding: 0.5rem 0; 
}

/* Comment image container */
.comment .comment-image {
  height: 12rem;
  width: 12rem;
  overflow: hidden;
}

/* Image inside the Comment image container */
.comment .comment-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* Comment content container */
.comment .comment-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 40rem;
          flex: 1 1 40rem;
}

/* Comment intro section */
.comment .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 0.5rem;
}

/* Comment Name Container */
.comment .intro .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

/* Author Name */
.comment .intro h3 {
  font-size: 1.8rem;
  line-height: 1;
  color: var(--black);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.2);
  padding-right: 1rem;
}

/* Comment Date */
.comment .intro h6 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--main-color);
}

/* Comment rating section */
.comment .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

/* Comment star icon */
.comment .rating .icon {
  font-size: 1.6rem;
  color: var(--main-color);
}

/* Comment rating value */
.comment .rating h5 {
  font-size: 1.6rem;
  color: var(--black);
}

/* Comment text */
.comment .text {
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
  padding-bottom: 1rem;
}

/* Comment Reply */
.comment .icon-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.3rem;
}

/* Comment Reply icon */
.comment .icon-item .icon {
  color: var(--main-color);
  font-size: 1.5rem;
}

/* Comment Reply text */
.comment .icon-item span {
  color: var(--main-color);
  font-size: 1.6rem;
}

/* Comment Reply, Hover effect */
.comment .icon-item:hover .icon,
.comment .icon-item:hover span {
  cursor: pointer;
  color: var(--secondary-color);
}