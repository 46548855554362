/* Background gradient and image */
.result-point{
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url("../../../assets/Background/Result.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url("../../../assets/Background/Result.jpg");
  background-position: center;
  background-size: cover; 
  background-attachment: fixed; 
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(45rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(45rem, 1fr));
  gap: 2rem;
}

/* Home Latest Result */
.home-result{
  overflow: hidden;
}  

/* Result slider container */
.result-slider{ 
  overflow: hidden; 
  position: relative;
}  

/* Result slider swiper container */
.result-slider .swiper{
  overflow: hidden;  
  padding-bottom: 6rem;
  padding-top: 2rem;
}

/* Result item */
.home-result .result-item{
  text-align: center;
  padding: 3rem 4rem;
  border: 0.15rem solid var(--white);
  background-color: #111;
}

/* Result Item Title */
.home-result .result-item h6{
  color: var(--white);
  font-size: 3rem;
  font-weight: 500;
  position: relative;
}

/* Title Underline */
.home-result .result-item h6:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6rem;
  height: 0.2rem;
  content: "";
  background-color: var(--white);
}

/* Centering the Title Underline */
.home-result .result-item h6:before {
  left: 50%; 
  margin-left: -3rem;
}

/* Introduction Section */
.home-result .result-item .intro{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 3rem;
  margin: 2rem 0;
}

/* Team Result Section */
.home-result .result-item .team-result{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2rem;
}

/* Team Name section */
.home-result .result-item .name{
  text-align: center;
}

/* Team Logo */
.home-result .result-item .name img{
  height: 10rem;
  width: 10rem;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

/* Team Name */
.home-result .result-item .name h3{
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--white);
}

/* Result section */
.home-result .result-item .result{
  text-align: center;
}

/* Team Inning Score */
.home-result .result-item .result h5{
  font-size: 2rem;
  color: var(--white);
}

/* Team Inning Overs played */
.home-result .result-item .result p{
  font-size: 1.6rem;
  color: var(--white);
}
 
/* Fixture content */
.home-result .result-item .fixture-content{
  text-align: center;
  font-size: 1.8rem;
  color: var(--white);
}

/* Point Table Container */
.result-point .point-table .points-container{
  padding: 2rem 0;
}