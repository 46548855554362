/* Aligning About content in the center */
.about .box-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;  
  justify-content: center; 
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 2rem;
} 

/* About images */
.about .image {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50rem;
      flex: 1 1 50rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
  margin-bottom: 5rem;
}

/* About First Image */
.about .sub-image1{
  height: 40rem;
  width: 80%;
  object-fit: cover;
}

/* About Second Image */
.about .sub-image2{
  height: 25rem;
  width: 25rem;
  object-fit: cover;
  -webkit-transform: translate(-15rem, 5rem);
          transform: translate(-15rem, 5rem);
  margin-right: -15rem;
  border: 0.5rem solid var(--white);
}

/* About content */
.about .content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 35rem;
      flex: 1 1 35rem;
}

/* About Heading */
.about .box-container .content h3{
  font-size: 4rem;
  color: var(--secondary-color);
  line-height: 1.3;
}

/* About Line Style */
.about .content .line{
  height: 0.2rem;
  width: 50%;
  background-color: var(--main-color);
  margin: 2rem 0;
}

/* About Paragraph style */
.about .box-container .content p{
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: var(--grey);
  line-height: 1.6;
}

/* About Button */
.about .content .btn {
  margin-top: 2rem;
}