/* Sponsors container */
.sponsors .box-container{
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 3rem;
  text-align: center;
} 

/* Sponsor Item */ 
.sponsor-item{
  height: 15rem; 
}

/* Sponsors Logo */
.sponsors img{
  height: 100%;
  width: 100%;
  object-fit: contain; 
}